<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_5_46)">
            <path
                d="M22.0828 11.3953C19.8609 6.71484 16.5023 4.35938 12 4.35938C7.49531 4.35938 4.13906 6.71484 1.91719 11.3977C1.82807 11.5864 1.78185 11.7925 1.78185 12.0012C1.78185 12.2099 1.82807 12.416 1.91719 12.6047C4.13906 17.2852 7.49765 19.6406 12 19.6406C16.5047 19.6406 19.8609 17.2852 22.0828 12.6023C22.2633 12.2227 22.2633 11.782 22.0828 11.3953ZM12 17.9531C8.21953 17.9531 5.45156 16.0359 3.49922 12C5.45156 7.96406 8.21953 6.04688 12 6.04688C15.7805 6.04688 18.5484 7.96406 20.5008 12C18.5508 16.0359 15.7828 17.9531 12 17.9531ZM11.9062 7.875C9.62812 7.875 7.78125 9.72188 7.78125 12C7.78125 14.2781 9.62812 16.125 11.9062 16.125C14.1844 16.125 16.0312 14.2781 16.0312 12C16.0312 9.72188 14.1844 7.875 11.9062 7.875ZM11.9062 14.625C10.4555 14.625 9.28125 13.4508 9.28125 12C9.28125 10.5492 10.4555 9.375 11.9062 9.375C13.357 9.375 14.5312 10.5492 14.5312 12C14.5312 13.4508 13.357 14.625 11.9062 14.625Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_5_46">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
